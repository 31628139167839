import { OverallMetricsResponse } from '@/shared/types/publicDisplays';
import { addDays } from 'date-fns';

export default (): OverallMetricsResponse => ({
  name: 'Pickings rate',
  lastDay: {
    date: new Date().getTime() - 86400,
    units: Math.random() * 10,
  },
  lastFiveDays: {
    newestDay: addDays(new Date(), 5).getTime(),
    oldestDay: new Date().getTime() - 86400,
    units: Math.random() * 100,
  },
});
