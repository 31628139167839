export enum HttpStatus {
  TEST = -1,
  UNAUTHORIZED_USER = 401,
  FORBIDDEN = 403,
  NO_CONNECTION = 502,
  SERVER_MAINTENANCE = 503,
  INACTIVE_USER = 700,
}

export enum Locale {
  PT = 'pt-PT',
  US = 'en-US',
}

export interface ChartPoint {
  x: number;
  y: number;
}

export enum TypeProgressBar {
  PRIMARY = 'PRIMARY',
  WARNING = 'WARNING',
  SECONDARY = 'SECONDARY',
  NEGATIVE = 'NEGATIVE',
  WINNER = 'WINNER',
}

export enum RouteAuthenticationRequirement {
  AUTHENTICATED,
  UNAUTHENTICATED,
  UNIVERSAL,
}

export enum GraphType {
  TEAM = 'TEAM',
  GENERIC = 'GENERIC',
}

export enum LayoutType {
  TEAM = 'TEAM',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum WIDGETS {
  WIDGET_1 = 'WIDGET_1',
  WIDGET_2 = 'WIDGET_2',
  WIDGET_3 = 'WIDGET_3',
  WIDGET_4 = 'WIDGET_4',
}

export enum EVENTS {
  METRIC_UPDATE = 'METRIC_UPDATE',
  SWAP_TEAM = 'SWAP_TEAM',
  SSE_OPEN = 'open',
  UPDATE_CHALLENGES = 'UPDATE_CHALLENGES',
}

export interface ResponseMetadata {
  totalItems?: number;
  lastUpdated?: number;
}
