import {
  ChallengeGoalStaticReference,
  ChallengeModeStaticReference,
  ChallengeTypeStaticReference,
} from '@/shared/types/challenges';
import {
  IndividualGameAndLeaderboardResponse,
  IndividualChallenge,
} from '@/shared/types/publicDisplays';

const mockIndividualChallenge1 = (): IndividualChallenge => {
  return {
    challengeLeaderboard: {
      users: [
        {
          position: 1,
          username: 'Money Glitch',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 19580.229932665825,
        },
        {
          position: 2,
          username: 'Florida',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatares Season 0 78x78-28.png',
          units: 14835.659980416298,
        },
        {
          position: 3,
          username: 'KAMERON BANKS',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 123344.170061826706,
        },
        {
          position: 4,
          username: 'Chizzoasdasdadsadsadsadsdsad',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 123344.170061826706,
        },
        {
          position: 5,
          username:
            'MostKnownUnknownasdadsad asdasdad car car car car car asdasdadas',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 123344.170061826706,
        },
        {
          position: 6,
          username: 'ADRIAN TROTTER',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 2746.200004339218,
        },
        {
          position: 7,
          username: 'WALTER GERHARTZ',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 123344.170061826706,
        },
        {
          position: 8,
          username: 'Sonnyb',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
        },
        {
          position: 9,
          username: 'Maracanthony Aponte',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 123344.170061826706,
        },
        {
          position: 10,
          username: 'user formerMemberasda',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_deleted.svg',
          units: 123344.170061826706,
        },
      ],
    },
    challengeInfo: {
      name: 'Staging in the door',
      endDate: 1713258000000,
      description: 'fastest loader wins',
      mode: {
        url: 'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/mode_freeForAll.png',
        staticReference: ChallengeModeStaticReference.PLAYER_VS_PLAYER,
      },
      type: {
        url: 'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/type_mostPointWins.png',
        staticReference: ChallengeTypeStaticReference.CLASSIC,
      },
      goal: ChallengeGoalStaticReference.MOST_POINTS_WIN,
      units: {},
      unitMeasureName: 'unitMeasure.cubes',
      reachedPercentage: true,
      metric: 'Loading ',
    },
  };
};

const mockIndividualChallenge2 = (): IndividualChallenge => {
  const goal = 10000;

  return {
    challengeLeaderboard: {
      users: [
        {
          position: 1,
          username: 'Money Glitch',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 19580.229932665825,
        },
        {
          position: 2,
          username: 'Florida',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatares Season 0 78x78-28.png',
          units: 14835.659980416298,
        },
        {
          position: 3,
          username: 'KAMERON BANKS',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 12747.499967575073,
        },
        {
          position: 4,
          username: 'Chizzo',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 12259.170061826706,
        },
        {
          position: 5,
          username: 'MostKnownUnknown',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 7341.159969806671,
        },
        {
          position: 6,
          username: 'ADRIAN TROTTER',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 2746.200004339218,
        },
        {
          position: 7,
          username: 'WALTER GERHARTZ',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
          units: 282.8200030326843,
        },
        {
          position: 8,
          username: 'Sonnyb',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
        },
        {
          position: 9,
          username: 'Maracanthony Aponte',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_default.svg',
        },
        {
          position: 10,
          username: 'user.formerMember',
          urlAvatar:
            'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/Avatar_deleted.svg',
        },
      ],
    },
    challengeInfo: {
      name: 'Staging in the door',
      endDate: 1713258000000,
      description: 'fastest loader wins',
      mode: {
        url: 'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/mode_freeForAll.png',
        staticReference: ChallengeModeStaticReference.PLAYER_VS_PLAYER,
      },
      type: {
        url: 'https://api.prod-us.vaibe.app/v2/storages/container/org1/image/type_mostPointWins.png',
        staticReference: ChallengeTypeStaticReference.CLASSIC,
      },
      goal: ChallengeGoalStaticReference.MOST_POINTS_WIN,
      units: {
        current: 8000,
        goal,
      },
      unitMeasureName: 'unitMeasure.cubes',
      reachedPercentage: true,
      metric: 'Loading ',
    },
  };
};

const mockIndividualChallenges = (): IndividualGameAndLeaderboardResponse => ({
  rotationDuration: 30,
  challenges: [mockIndividualChallenge1(), mockIndividualChallenge2()],
});

export default mockIndividualChallenges;
