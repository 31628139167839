import { DailyProductivityResponse } from '@/shared/types/publicDisplays';

export default (length = 4): DailyProductivityResponse => {
  return {
    chartPoints: Array(length)
      .fill(0)
      .map(() => ({
        x: 1_672_000_000 + Math.floor(Math.random() * 100_000),
        y: Math.floor(Math.random() * 100_000),
      })),
    name: 'Daily productivity',
    yDescription: 'y description',
  };
};
