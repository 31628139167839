import { exampleMode, exampleType } from '@/shared/constants/icons';
import { ChallengeOfTheDayResponse } from '@/shared/types/publicDisplays';

export default (): ChallengeOfTheDayResponse => ({
  name: 'challenge-name',
  type: exampleType,
  mode: exampleMode,
  endDate: new Date().setHours(24, 59, 59, 999),
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  units: {
    current: Math.floor(Math.random() * 1000),
    goal: 1500,
  },
});
