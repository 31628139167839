export enum ChallengeModeStaticReference {
  TEAM_DUEL = 'CM_5',
  TEAM_VS_TEAM = 'CM_4',
  TOGETHER_AS_TEAM = 'CM_3',
  TEAM_COOPERATION = 'CM_6',
  PLAYER_VS_PLAYER = 'CM_1',
  SINGLE_PLAYER = 'CM_7',
}

export enum ChallengeTypeStaticReference {
  CLASSIC = 'CT_3',
  FASTEST = 'CT_4',
  REACHED_AS_TEAM = 'CT_5',
  ACHIEVER = 'CT_6',
}

export enum ChallengeGoalStaticReference {
  TARGET = 'CG_6',
  MOST_POINTS_WIN = 'CG_4',
  LEAST_POINTS_WIN = 'CG_5',
}
