<script setup lang="ts">
import { onBeforeMount } from 'vue';
import router from './router';

onBeforeMount(() => {
  router.beforeEach((to) => {
    document.body.className = '';
    document.body.className = String(to.meta.bodyClass);
  });
});
</script>

<template>
  <router-view />
</template>
