import { isEmpty } from 'lodash';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { RouteAuthenticationRequirement } from '@/shared/types/generic';
import useTokenStore from '@/store/token/useTokenStore';

const TVDisplaysView = () =>
  import(
    /* webpackChunkName: "tv" */ '@/views/TVDisplaysView/TVDisplaysView.vue'
  );
const LoginView = () =>
  import(/* webpackChunkName: "tv" */ '@/views/LoginView/LoginView.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: { bodyClass: 'background-login' },
    children: [
      {
        path: ':organizationName',
        component: LoginView,
      },
    ],
  },
  {
    path: '/home',
    name: 'home',
    component: TVDisplaysView,
    meta: {
      bodyClass: 'background-default',
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  const tokenStore = useTokenStore();

  const routeRequiresAuthentication = to.matched.some(
    (route) =>
      route.meta.authenticationRequirement ===
      RouteAuthenticationRequirement.AUTHENTICATED,
  );

  const isAuthenticated = !isEmpty(tokenStore.settings.accessToken);

  if (
    routeRequiresAuthentication &&
    !isAuthenticated &&
    !tokenStore.settings.loginWithoutPin
  ) {
    next('/');
    return;
  }

  next();
});

export default router;
