/* istanbul ignore file */

import { use, ComposeOption } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  TooltipComponent,
  GridComponent,
  GridComponentOption,
  LegendComponent,
} from 'echarts/components';
import {
  LineChart,
  BarChart,
  BarSeriesOption,
  LineSeriesOption,
} from 'echarts/charts';

use([
  CanvasRenderer,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  LineChart,
]);

export type ECOption = ComposeOption<
  LineSeriesOption | BarSeriesOption | GridComponentOption
>;

export interface ECEventParams {
  componentType: string;
  dataIndex: number;
  data: number | string | Record<string, unknown>;
  event: unknown;
  value: number | string | unknown[];
}

export interface ECYAxisEventParams extends ECEventParams {
  targetType: string;
  yAxisIndex: number;
}

export interface ECSeriesEventParams extends ECEventParams {
  componentType: 'series';
  name: string;
  seriesType: string;
  seriesIndex: number;
  seriesName: string;
  dataIndex: number;
  dataType?: string;
  color: string;
}
