import { ProductivityByTeamResponse } from '@/shared/types/publicDisplays';

export default (length: number): ProductivityByTeamResponse => {
  return {
    teamName: 'Morning team',
    teamTime: {
      start: new Date().setHours(8, 0, 0, 0),
      end: new Date().setHours(17, 0, 0, 0),
    },
    charts: Array.from({ length }, (_, index) => ({
      name: `Metric ${index}`,
      description: ``,
      yDescription: `Chart ${index} y-axis label`,
      amount: 1110,
      chartPoints: Array.from({ length: 5 }, () => ({
        x: 1_672_000_000 + Math.floor(Math.random() * 100_000),
        y: Math.floor(Math.random() * 100_000),
      })),
      rotationDuration: index + 1,
    })),
  };
};
