import { LayoutType } from '@/shared/types/generic';
import { ResponseLogin } from '@/shared/types/token';

export default (): ResponseLogin => ({
  publicDisplayLayout: LayoutType.TEAM,
  accessToken: 'lskdjfsdf-sdjflskdjfds-fksdjflsj-ssioaaiou',
  teamReference: '2222-22344-44555',
  languageCode: 'en-US',
  organizationName: 'City',
});
